export const trackingData = [
  {
    tabName: 'RKN - MNS-PSK',
    tabData: {
      'leg 1': [
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Rotterdam',
          country: 'Netherlands',
          countryShotName: 'NL',
          dateType: 'ATD',
          date: '11 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATD',
          date: '15 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATD',
          date: '15 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATA',
          date: '15 DEC 02:40 PM',
        },
      ],
      'leg 2': [
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Rotterdam',
          country: 'Netherlands',
          countryShotName: 'NL',
          dateType: 'ATD',
          date: '11 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATA',
          date: '15 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATA',
          date: '15 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATA',
          date: '15 DEC 02:40 PM',
        },
      ],
    },
  },
  {
    tabName: 'RKN - PSK',
    tabData: {
      'leg 1': [
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Rotterdam',
          country: 'Netherlands',
          countryShotName: 'NL',
          dateType: 'ATD',
          date: '11 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATA',
          date: '15 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATA',
          date: '15 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATA',
          date: '15 DEC 02:40 PM',
        },
      ],
      'leg 2': [
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Rotterdam',
          country: 'Netherlands',
          countryShotName: 'NL',
          dateType: 'ATD',
          date: '11 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATA',
          date: '15 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATA',
          date: '15 DEC 02:40 PM',
        },
        {
          id: '69968b54-56a4-46b0-a784-38d5dc41d553',
          portLocation: 'Chennai',
          countryShotName: 'IND',
          country: 'India',
          dateType: 'ATA',
          date: '15 DEC 02:40 PM',
        },
      ],
    },
  },
];
